$primary: #2f80ed;

@import '~bootstrap/scss/bootstrap.scss';

html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: Gilroy;
  background-color: #f3f3f3;
}

a {
  text-decoration: none;
}

.tooltip {
  font-size: 14px;
  font-family: 'Gilroy' !important;
  z-index: 999999999;
  max-width: 400px !important;
}

.scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0);
  border-radius:16px;
  border:5px solid #fff;
}

.scrollbar:hover::-webkit-scrollbar-thumb {
background-color: #a0a0a5;
}

.scrollbar::-webkit-scrollbar-thumb:hover {
  background-color:#a0a0a5;
  border:4px solid #f4f4f4
}

.ck {
  font-family: 'Gilroy' !important;
}

.ck-body-wrapper {
  position: fixed;
  z-index: var(--ck-z-default);
}

.ck-block-toolbar-button {
  width: 26px !important;
  height: 26px !important;
  min-width: 26px !important;
  min-height: 26px !important;
  border-radius: 6px !important;
}

.ck-block-toolbar-button:hover {
  background-color: #999999;
}

.ck-block-toolbar-button::before {
  content: url(images/ck-icon.svg);
}

.ck-block-toolbar-button .ck-icon {
  display: none;
}

.Toastify__toast {
  border: 1px solid #CCC;
  box-shadow: 0px 2px 2px rgb(0 0 0 / 15%) !important;
  min-height: auto !important;
  padding: 6px 8px 8px 8px;
}

.Toastify__toast-body {
  font-family: 'Gilroy' !important;
}

.Toastify__progress-bar--success {
  background-color: #6FCF97 !important;
}

.Toastify__toast-icon {
  display: none;
}
