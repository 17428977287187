.campus-tooltip {
  --balloon-tip-top: 0;

  --extra-invisible-space-on-the-left-side: 0;
  --extra-invisible-space-on-the-right-side: 0;
  --extra-invisible-space-on-the-other-sides: 0;

  padding: 10px 0;
  position: absolute;
  z-index: 9999;
  font-size: 12px;
  border-radius: 6px;
  color: white;
  background-color: #4a4a4a;

  .title,
  .engagement,
  .performance,
  .feedback {
    margin: 0;
  }

  .engagement,
  .performance {
    white-space: nowrap;
  }

  .title {
    margin-bottom: 6px;
    font-size: 16px;
  }

  .feedback {
    margin-top: 8px;
    min-width: 23ch;
    max-width: 40ch;
  }

  .group-items-list {
    display: flex;
    row-gap: 9px;
    flex-direction: column;

    .list-row {
      padding: 0 10px;

      display: flex;
      align-items: center;

      .bubble {
        text-align: center;
        border-radius: 50%;

        display: flex;
        align-items: center;
        justify-content: center;

        .bubble__text {
          font-size: 9px;
          font-weight: 600;

          pointer-events: none;

          display: inline-block;
          line-height: normal;
          vertical-align: middle;
        }
      }

      .bubble.with-bg-image {
        background-size: cover;
        border: 2px solid white;
      }

      .text {
        margin: 0 15px 0 5px;
        font-size: 12px;
        font-weight: 600;
        white-space: nowrap;
        letter-spacing: 0.12px;
        pointer-events: none;
      }

      .icon {
        display: block;
        margin-left: auto;
      }
    }

    .list-row.clickable {
      cursor: pointer;
    }

    .list-row:hover .bubble {
      scale: 1.2;
    }

    .list-row:hover .text {
      transform: translateX(3px);
    }
  }

  &::before {
    content: '';
    z-index: -1;
    rotate: 45deg;
    background: inherit;
    width: 8px;
    height: 8px;
    position: absolute;
    top: var(--balloon-tip-top, 0);
    left: -4px;
  }

  &::after {
    content: '';
    z-index: -1;
    position: absolute;
    left: var(--extra-invisible-space-on-the-left-side, 0);
    right: var(--extra-invisible-space-on-the-right-side, 0);
    top: var(--extra-invisible-space-on-the-other-sides, 0);
    bottom: var(--extra-invisible-space-on-the-other-sides, 0);
  }
}

.campus-tooltip.to-the-left-of-the-bubble {
  .group-items-list {
    .list-row {
      flex-direction: row-reverse;

      .text {
        margin: 0 5px 0 15px;
      }

      .icon {
        margin-left: revert;
        margin-right: auto;
        rotate: 180deg;
      }
    }

    .list-row:hover .text {
      transform: translateX(-3px);
    }
  }

  &::before {
    left: revert;
    right: -4px;
  }
}

.campus-tooltip.not-displayed {
  display: none;
}

.campus-tooltip.just-invisible {
  visibility: hidden;
}

.campus-tooltip.with-inline-padding {
  padding: 10px;
}
